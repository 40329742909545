var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-6 mb-3" },
    [
      _c("p", { staticClass: "caption" }, [_vm._v("Layer Details")]),
      _c(
        "v-card",
        [
          _c("v-card-text", [
            ["U", "F"].includes(_vm.layer.service_type)
              ? _c(
                  "p",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          text: "",
                          color: "primary",
                          id: "utilisyncFields",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showUtiliSyncFieldsDialog = true
                          },
                        },
                      },
                      [_vm._v(" UtiliSync Fields ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            ["F"].includes(_vm.layer.service_type)
              ? _c(
                  "p",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary", id: "gisFields" },
                        on: {
                          click: function ($event) {
                            _vm.showGisFieldsDialog = true
                          },
                        },
                      },
                      [_vm._v(" GIS Fields ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.layer.parent_map_service_id
              ? _c(
                  "p",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary", id: "mapSymbols" },
                        on: {
                          click: function ($event) {
                            _vm.showCustomLayerSymbologyDialog = true
                          },
                        },
                      },
                      [_vm._v(" Map Symbols ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "p",
              [
                _c(
                  "v-btn",
                  {
                    attrs: { text: "", color: "primary", id: "forms" },
                    on: {
                      click: function ($event) {
                        _vm.showFormsDialog = true
                      },
                    },
                  },
                  [_vm._v(" Forms ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.showUtiliSyncFieldsDialog
        ? _c("EditUtiliSyncFieldsDialog", {
            attrs: {
              mapServiceId: _vm.layer.map_service_id,
              showUtiliSyncFieldsDialog: _vm.showUtiliSyncFieldsDialog,
            },
            on: {
              "edit-utilisync-fields-dialog-close": function ($event) {
                _vm.showUtiliSyncFieldsDialog = false
              },
              "utilisync-fields-saved": _vm.onUtilisyncFieldsSaved,
            },
          })
        : _vm._e(),
      _vm.showGisFieldsDialog
        ? _c("EditGisFieldsDialog", {
            attrs: {
              mapServiceId: _vm.layer.map_service_id,
              showGisFieldsDialog: _vm.showGisFieldsDialog,
            },
            on: {
              "edit-gis-fields-dialog-close": function ($event) {
                _vm.showGisFieldsDialog = false
              },
              "gis-fields-saved": _vm.onGisFieldsSaved,
            },
          })
        : _vm._e(),
      _vm.showCustomLayerSymbologyDialog
        ? _c("EditCustomLayerSymbologyDialog", {
            attrs: {
              showCustomLayerSymbologyDialog:
                _vm.showCustomLayerSymbologyDialog,
              mapServiceId: _vm.layer.map_service_id,
              layerObj: _vm.layer,
            },
            on: {
              "custom-symbology-dialog-close": function ($event) {
                _vm.showCustomLayerSymbologyDialog = false
              },
              "symbology-saved": function ($event) {
                _vm.showCustomLayerSymbologyDialog = false
              },
            },
          })
        : _vm._e(),
      _vm.showFormsDialog
        ? _c("FormsDialog", {
            attrs: {
              showFormsDialog: _vm.showFormsDialog,
              mapServiceId: _vm.layer.map_service_id,
            },
            on: {
              "forms-dialog-close": function ($event) {
                _vm.showFormsDialog = false
              },
              "open-edit-form-dialog": function ($event) {
                return _vm.$emit("open-edit-form-dialog", $event)
              },
              "create-new-form": function ($event) {
                return _vm.$emit("create-new-form")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }