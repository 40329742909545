<template>
  <v-dialog
    :value="showInvalidPasswordResetTokenDialog"
    max-width="500px"
    persistent
  >
    <v-card>
      <v-card-text class="py-5">
        Password creation link has expired. Please request a new link via the
        <strong>Reset Password</strong> button and try again.
      </v-card-text>
      <v-card-actions class="d-flex justify-end pa-3">
        <v-btn
          id="reset-password"
          color="primary"
          type="submit"
          @click="$emit('close')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "InvalidPasswordResetTokenDialog",
  props: {
    showInvalidPasswordResetTokenDialog: Boolean,
  },
};
</script>
