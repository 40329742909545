var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "validation-observer",
    { ref: "headerForm" },
    [
      _c(
        "v-toolbar",
        {
          ref: "toolbar",
          staticClass: "elevation-0",
          attrs: { color: "primary", height: "56px" },
        },
        [
          _c(
            "v-list-item",
            { staticClass: "px-0", attrs: { "two-line": "" } },
            [
              _c("v-list-item-content", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between" },
                  [
                    _c(
                      "v-list-item-title",
                      { staticClass: "white--text text-h6" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("itemTitle")(
                                _vm.selectedControlType,
                                _vm.selectedItem
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", color: "white" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("form-control-form-submitted")
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.onSubmit()
              _vm.$emit("form-control-form-submitted")
            },
          },
        },
        [
          _c(
            "v-list",
            {
              staticClass: "overflow-y-auto",
              staticStyle: { "max-height": "calc(100vh - 56px)" },
              attrs: { dense: "" },
            },
            [
              ["HEADER"].includes(_vm.selectedItemOptions.information.type)
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("validation-provider", {
                            attrs: { name: "Label", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors, valid }) {
                                    return [
                                      _c("v-text-field", {
                                        ref: "labelInput",
                                        attrs: {
                                          label: "Label",
                                          "hide-details": "auto",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "primary",
                                          name: "name",
                                          disabled: !_vm.canManageForms,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onSubmit()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.selectedItemOptions.information
                                              .label,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.selectedItemOptions
                                                .information,
                                              "label",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedItemOptions.information.label",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3257438385
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              ["TEXT"].includes(_vm.selectedItemOptions.information.type)
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "mt-3 pt-0" },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "elevation-0 d-flex justify-space-between",
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "caption my-0",
                                        style: _vm.canManageForms
                                          ? "color: #4caf50"
                                          : "color: #aaaaaa",
                                      },
                                      [_vm._v(" Text ")]
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "ml-1",
                                        attrs: {
                                          disabled: !_vm.canManageForms,
                                          size: "16",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.showTextDialog = true
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.mdiPencil) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("p", {
                                  staticClass: "my-0 cursor-pointer",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.selectedItemOptions.information.label
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showTextDialog = true
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "v-dialog",
                                {
                                  attrs: {
                                    "max-width": "600px",
                                    "retain-focus": false,
                                    fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                                  },
                                  model: {
                                    value: _vm.showTextDialog,
                                    callback: function ($$v) {
                                      _vm.showTextDialog = $$v
                                    },
                                    expression: "showTextDialog",
                                  },
                                },
                                [
                                  _c("TextForm", {
                                    on: {
                                      "text-form-close": function ($event) {
                                        _vm.showTextDialog = false
                                      },
                                      input: function ($event) {
                                        _vm.showTextDialog = false
                                        _vm.$emit(
                                          "input",
                                          _vm.selectedItemOptions
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.selectedItemOptions.information
                                          .label,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selectedItemOptions.information,
                                          "label",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "selectedItemOptions.information.label",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedItemOptions.information.type === "URL"
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("validation-provider", {
                            attrs: { name: "URL", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors, valid }) {
                                    return [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "URL",
                                          "hide-details": "auto",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "primary",
                                          name: "name",
                                          hint: "Input a valid URL",
                                          disabled: !_vm.canManageForms,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onSubmit()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.selectedItemOptions.information
                                              .url,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.selectedItemOptions
                                                .information,
                                              "url",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedItemOptions.information.url",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3382305505
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedItemOptions.information.type === "URL"
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("validation-provider", {
                            attrs: { name: "Label", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors, valid }) {
                                    return [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Label",
                                          "hide-details": "auto",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "primary",
                                          name: "name",
                                          hint: "Input a valid URL",
                                          disabled: !_vm.canManageForms,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onSubmit()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.selectedItemOptions.information
                                              .label,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.selectedItemOptions
                                                .information,
                                              "label",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedItemOptions.information.label",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3044453793
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedItemOptions.information.type === "IMAGE"
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("validation-provider", {
                            attrs: { name: "Label" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors, valid }) {
                                    return [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Label",
                                          "hide-details": "auto",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "primary",
                                          name: "name",
                                          disabled: !_vm.canManageForms,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onSubmit()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.selectedItemOptions.information
                                              .label,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.selectedItemOptions
                                                .information,
                                              "label",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedItemOptions.information.label",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2340074918
                            ),
                          }),
                          _c("validation-provider", {
                            attrs: { name: "Image URL", rules: "required" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors, valid }) {
                                    return [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Image URL",
                                          "hide-details": "auto",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "primary",
                                          name: "name",
                                          hint: "Input a valid URL",
                                          disabled: !_vm.canManageForms,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onSubmit()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.selectedItemOptions.information
                                              .url,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.selectedItemOptions
                                                .information,
                                              "url",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedItemOptions.information.url",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1030459110
                            ),
                          }),
                          _c("validation-provider", {
                            attrs: { name: "Image URL" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors, valid }) {
                                    return [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Open URL",
                                          "hide-details": "auto",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "primary",
                                          name: "name",
                                          hint: "Input a valid URL",
                                          disabled: !_vm.canManageForms,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onSubmit()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.selectedItemOptions.information
                                              .openUrl,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.selectedItemOptions
                                                .information,
                                              "openUrl",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "selectedItemOptions.information.openUrl",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3009738017
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-list-item",
                { staticClass: "my-0 py-0" },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("validation-provider", {
                        attrs: { name: "Visible" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors, valid }) {
                              return [
                                _c("v-select", {
                                  attrs: {
                                    autocomplete: "off",
                                    items: _vm.createOptions,
                                    label: "Create",
                                    "error-messages": errors,
                                    success: valid,
                                    "item-text": "label",
                                    "item-value": "value",
                                    "hide-details": "",
                                    disabled: !_vm.canManageForms,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onSubmit()
                                    },
                                  },
                                  model: {
                                    value: _vm.createCondition,
                                    callback: function ($$v) {
                                      _vm.createCondition = $$v
                                    },
                                    expression: "createCondition",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      ["conditional"].includes(_vm.createCondition)
                        ? _c("CreateComparisonConditionForm", {
                            attrs: {
                              formDefinition: _vm.formDefinition,
                              selectedItem: _vm.selectedItem,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.onSubmit()
                              },
                            },
                            model: {
                              value: _vm.selectedItemOptions.information.create,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedItemOptions.information,
                                  "create",
                                  $$v
                                )
                              },
                              expression:
                                "selectedItemOptions.information.create",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("AdvancedInfoSettingsForm", {
                        attrs: {
                          formDefinition: _vm.formDefinition,
                          selectedItem: _vm.selectedItem,
                          layer: _vm.layer,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onSubmit()
                          },
                        },
                        model: {
                          value: _vm.selectedItemOptions,
                          callback: function ($$v) {
                            _vm.selectedItemOptions = $$v
                          },
                          expression: "selectedItemOptions",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c("v-list-item-content", [
                    _vm.hasAdvancedSettings
                      ? _c("div", { staticClass: "d-flex flex-wrap gap" }, [
                          _vm.hasVisibleSettings
                            ? _c(
                                "section",
                                {
                                  staticClass:
                                    "text-uppercase d-flex primary--text gap align-center",
                                  on: {
                                    click: function ($event) {
                                      if (_vm.canManageForms) {
                                        _vm.showAdvancedInfoVisibilitySettingsDialog = true
                                      }
                                    },
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "Visible " + _vm._s(_vm.visibleSetting)
                                    ),
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled: !_vm.canManageForms,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiPencil) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _c(
                          "div",
                          { staticClass: "d-flex align-center justify-center" },
                          [_vm._v(" No advanced settings ")]
                        ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "mt-0 mb-0 pt-0 pb-0" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            type: "submit",
                            width: "100%",
                            disabled: !_vm.canManageForms,
                          },
                        },
                        [_vm._v(" Update Item ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showAdvancedInfoVisibilitySettingsDialog
            ? _c("AdvancedInfoVisibilitySettingsDialog", {
                attrs: {
                  showAdvancedInfoVisibilitySettingsDialog:
                    _vm.showAdvancedInfoVisibilitySettingsDialog,
                  formDefinition: _vm.formDefinition,
                  layer: _vm.layer,
                  selectedItem: _vm.selectedItem,
                },
                on: {
                  "advanced-settings-dialog-close": function ($event) {
                    _vm.showAdvancedInfoVisibilitySettingsDialog = false
                  },
                  input: function ($event) {
                    return _vm.onSubmit()
                  },
                },
                model: {
                  value: _vm.selectedItemOptions,
                  callback: function ($$v) {
                    _vm.selectedItemOptions = $$v
                  },
                  expression: "selectedItemOptions",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }