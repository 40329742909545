<template>
  <v-card
    class="elevation-0 mx-auto overflow-y-auto"
    width="calc(100vw - 56px)"
    height="calc(100vh - 56px)"
  >
    <v-card-text class="elevation-0">
      <div style="max-width: 600px; margin: 0 auto">
        <v-row>
          <v-col
            cols="12"
            v-for="(emailSetting, index) of formDefinition.form.emailSettings"
            :key="emailSetting.id"
          >
            <v-card class="card-background elevation-0">
              <v-toolbar class="elevation-0" color="#e5e5e5">
                <v-toolbar-title> Email </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item class="pl-0 pr-0">
                      <v-btn
                        text
                        @click.stop="showConfirmDeleteDialog = true"
                        :disabled="!canManageForms"
                      >
                        <v-icon class="mr-2">
                          {{ mdiDelete }}
                        </v-icon>
                        Delete
                      </v-btn>
                      <ConfirmEmailSettingDeleteDialog
                        :showConfirmDialog="showConfirmDeleteDialog"
                        @confirm-delete="
                          removeEmailSetting(index);
                          showConfirmDeleteDialog = false;
                        "
                        @cancel-delete="showConfirmDeleteDialog = false"
                      />
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>

              <v-card-text>
                <form>
                  <EmailFromToForm
                    :index="index"
                    v-model="formDefinition"
                    @input="$emit('input', formDefinition)"
                  />

                  <v-card class="mt-3 mb-3">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6">
                          <validation-provider
                            v-slot="{ errors, valid }"
                            name="Subject"
                            rules="required"
                          >
                            <v-text-field
                              label="Subject *"
                              hide-details="auto"
                              :error-messages="errors"
                              :success="valid"
                              color="primary"
                              name="value"
                              v-model="
                                formDefinition.form.emailSettings[index].subject
                              "
                              @input="$emit('input', formDefinition)"
                              :disabled="!canManageForms"
                            >
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" md="6">
                          <validation-provider
                            v-slot="{ errors, valid }"
                            name="Attachment Name"
                            rules="required"
                          >
                            <v-text-field
                              label="Attachment Name *"
                              hide-details="auto"
                              :error-messages="errors"
                              :success="valid"
                              color="primary"
                              name="value"
                              v-model="
                                formDefinition.form.emailSettings[index]
                                  .attachmentName
                              "
                              @input="$emit('input', formDefinition)"
                              :disabled="!canManageForms"
                            >
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-card class="mt-3 mb-3">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="3">
                          <validation-provider
                            v-slot="{ errors, valid }"
                            name="Send Email"
                            rules="required"
                          >
                            <v-select
                              autocomplete="off"
                              label="Send Email *"
                              hide-details="auto"
                              :error-messages="errors"
                              :success="valid"
                              color="primary"
                              name="sendEmail"
                              :items="sendEmailOptions"
                              item-text="label"
                              item-value="value"
                              v-model="sendEmailSettings[index]"
                              @input="onSendEmailInput(index)"
                              :disabled="!canManageForms"
                            >
                            </v-select>
                          </validation-provider>
                        </v-col>

                        <v-col
                          cols="12"
                          md="3"
                          v-if="!['ALWAYS'].includes(sendEmailSettings[index])"
                        >
                          <validation-provider
                            v-slot="{ errors, valid }"
                            name="Form Question"
                            rules="required"
                          >
                            <v-select
                              autocomplete="off"
                              label="Form Question *"
                              hide-details="auto"
                              :error-messages="errors"
                              :success="valid"
                              color="primary"
                              name="dependantId"
                              :items="questionChoices"
                              item-text="label"
                              item-value="value"
                              v-model="
                                formDefinition.form.emailSettings[index]
                                  .condition.dependantId
                              "
                              @input="$emit('input', formDefinition)"
                              :disabled="!canManageForms"
                            >
                            </v-select>
                          </validation-provider>
                        </v-col>

                        <v-col
                          cols="12"
                          md="3"
                          v-if="!['ALWAYS'].includes(sendEmailSettings[index])"
                        >
                          <validation-provider
                            v-slot="{ errors, valid }"
                            name="Comparison"
                            rules="required"
                          >
                            <v-select
                              autocomplete="off"
                              label="Comparison *"
                              hide-details="auto"
                              :error-messages="errors"
                              :success="valid"
                              color="primary"
                              name="dependantId"
                              :items="comparisonTypes"
                              item-text="label"
                              item-value="value"
                              v-model="
                                formDefinition.form.emailSettings[index]
                                  .condition.condition
                              "
                              @input="$emit('input', formDefinition)"
                              :disabled="!canManageForms"
                            >
                            </v-select>
                          </validation-provider>
                        </v-col>

                        <v-col
                          cols="12"
                          md="3"
                          v-if="!['ALWAYS'].includes(sendEmailSettings[index])"
                        >
                          <validation-provider
                            v-slot="{ errors, valid }"
                            name="Value"
                            :rules="{
                              required: true,
                              numeric: isNumericOnly(index),
                            }"
                          >
                            <v-text-field
                              label="Value *"
                              hide-details="auto"
                              :error-messages="errors"
                              :success="valid"
                              color="primary"
                              name="value"
                              v-model="
                                formDefinition.form.emailSettings[index]
                                  .condition.value
                              "
                              @input="$emit('input', formDefinition)"
                              :disabled="!canManageForms"
                            >
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>

                  <v-card class="mt-3 mb-3">
                    <v-card-text>
                      <v-switch
                        label="Rich Text"
                        v-model="
                          formDefinition.form.emailSettings[index].bodyRichText
                        "
                        :disabled="!canManageForms"
                      ></v-switch>

                      <validation-provider
                        name="Body"
                        rules="required"
                        v-slot="{ errors, valid }"
                      >
                        <QuillEditor
                          v-if="
                            formDefinition.form.emailSettings[index]
                              .bodyRichText
                          "
                          :errors="errors"
                          :valid="valid"
                          :disabled="!canManageForms"
                          v-model="
                            formDefinition.form.emailSettings[index].body
                          "
                          @input="$emit('input', formDefinition)"
                        />
                        <v-textarea
                          v-else
                          :errors="errors"
                          :valid="valid"
                          v-model="
                            formDefinition.form.emailSettings[index].body
                          "
                          label="Body"
                          @input="$emit('input', formDefinition)"
                          :disabled="!canManageForms"
                        ></v-textarea>
                      </validation-provider>
                    </v-card-text>
                  </v-card>
                </form>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              @click="addEmailSetting"
              :disabled="!canManageForms"
            >
              + Email
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import EmailFromToForm from "@/components/forms/form-builder-edit-form/email-form/EmailFromToForm";
import ConfirmEmailSettingDeleteDialog from "@/components/forms/form-builder-edit-form/email-form/ConfirmEmailSettingDeleteDialog";
import { cloneDeep } from "lodash";
import { comparisonTypes } from "@/constants/choices";
import questionsMixin from "@/mixins/questionsMixin";
import { mdiDelete } from "@mdi/js";
import { v4 as uuidv4 } from "uuid";
import { tableCellClassList } from "@/constants/tableCellClassList";
import QuillEditor from "@/components/shared/QuillEditor";
import permissionsMixin from "@/mixins/permissionsMixin";

const sendEmailOptions = [
  {
    value: "ALWAYS",
    label: "Always",
  },
  {
    value: "CONDITIONAL",
    label: "Conditional",
  },
];

export default {
  name: "EmailForm",
  props: {
    value: Object,
  },
  mixins: [questionsMixin, permissionsMixin],
  computed: {
    questionChoices() {
      return this.questions.map(({ id, number, question: { label } = {} }) => ({
        value: id,
        label: `${number} ${label}`,
      }));
    },
  },
  components: {
    QuillEditor,
    EmailFromToForm,
    ConfirmEmailSettingDeleteDialog,
  },
  data() {
    return {
      formDefinition: {},
      sendEmailOptions,
      sendEmailSettings: [],
      comparisonTypes,
      mdiDelete,
      showConfirmDeleteDialog: false,
      tableCellClassList,
    };
  },
  methods: {
    isNumericOnly(index) {
      const { condition } =
        this.formDefinition.form.emailSettings[index].condition;
      return !["NOT_EQUAL", "EQUAL"].includes(condition);
    },
    onSendEmailInput(index) {
      if (this.sendEmailSettings[index] === "ALWAYS") {
        this.formDefinition.form.emailSettings[index].condition = {
          condition: "ALWAYS",
          dependantId: null,
          value: null,
        };
      } else {
        this.formDefinition.form.emailSettings[index].condition.condition =
          "NOT_EQUAL";
      }
      this.$emit("input", this.formDefinition);
    },
    addEmailSetting() {
      const emailSetting = {
        condition: {
          condition: "ALWAYS",
        },
      };
      this.formDefinition.form.emailSettings.push(emailSetting);
      this.$emit("input", this.formDefinition);
    },
    removeEmailSetting(index) {
      this.formDefinition.form.emailSettings.splice(index, 1);
      this.$emit("input", this.formDefinition);
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
        if (!Array.isArray(this.formDefinition.form.emailSettings)) {
          this.formDefinition.form.emailSettings = [];
        }
        this.sendEmailSettings = this.formDefinition.form.emailSettings.map(
          (e) => {
            if (e.condition.condition === "ALWAYS") {
              return "ALWAYS";
            }
            return "CONDITIONAL";
          }
        );
        this.formDefinition.form.emailSettings =
          this.formDefinition.form.emailSettings.map((s) => {
            const id = uuidv4();
            if (!Object.prototype.hasOwnProperty.call(s, "bodyRichText")) {
              return {
                id,
                ...s,
                bodyRichText: true,
              };
            }

            return {
              id,
              ...s,
            };
          });
      },
    },
  },
};
</script>

<style scoped>
textarea {
  border: none;
  resize: none;
}

textarea:focus {
  outline: none;
}

.card-background {
  background-color: #f1f2f1;
}
</style>
