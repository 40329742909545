var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showInvalidPasswordResetTokenDialog,
        "max-width": "500px",
        persistent: "",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "py-5" }, [
            _vm._v(
              " Password creation link has expired. Please request a new link via the "
            ),
            _c("strong", [_vm._v("Reset Password")]),
            _vm._v(" button and try again. "),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end pa-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "reset-password",
                    color: "primary",
                    type: "submit",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }