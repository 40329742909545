<template>
  <v-dialog :value="showAddLayerDialog" max-width="600" close persistent>
    <v-card>
      <validation-observer ref="addLayerForm" v-slot="{ valid: isFormValid }">
        <form @submit.prevent="createLayer" id="portal-url-form">
          <v-toolbar dark color="primary" class="elevation-0">
            <v-toolbar-title>Add Related Layer</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="$emit('close')">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text class="pa-5">
            <validation-provider
              v-slot="{ errors, valid }"
              name="Layer Name"
              :rules="{
                required: true,
              }"
            >
              <v-text-field
                v-model="layerName"
                label="Layer Name"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="layerName"
                persistent-hint
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="Reference Field"
              :rules="{
                required: true,
              }"
            >
              <v-text-field
                v-model="referenceField"
                label="Reference Field"
                hide-details="auto"
                :error-messages="errors"
                :success="valid"
                color="primary"
                name="referenceField"
                persistent-hint
                :append-outer-icon="mdiInformationOutline"
              />
            </validation-provider>
          </v-card-text>
          <v-card-actions class="pa-5 d-flex justify-end">
            <v-btn
              color="primary"
              @click="createLayer"
              :disabled="!isFormValid"
            >
              Create Layer
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiInformationOutline } from "@mdi/js";
import axios from "axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "AddLayerDialog",
  props: {
    showAddLayerDialog: Boolean,
    layer: Object,
  },
  data() {
    return {
      mdiClose,
      mdiInformationOutline,
      layerName: "",
      referenceField: "",
    };
  },
  methods: {
    async createLayer() {
      const success = await this.$refs.addLayerForm.validate();
      if (!success) {
        return;
      }
      const { layerName, layer } = this;
      const payload = {
        service_name: layerName,
        service_type: "U",
        parent_map_service_id: layer.map_service_id,
      };
      const {
        data: {
          results: { map_service: mapService },
        },
      } = await axios.post(`${APIURL}/map_services`, payload);

      const { map_service_id: childMapServiceId } = mapService;
      const { referenceField } = this;
      const data = {
        map_service_id: childMapServiceId,
        name: referenceField,
        type: "string",
        editable: true,
        visible: true,
      };
      const {
        data: { results: gisDataField },
      } = await axios.post(`${APIURL}/gis_data_fields`, data);

      const updatePayload = {
        ...payload,
        ref_field: gisDataField.gis_data_field_id,
      };
      await axios.put(
        `${APIURL}/map_services/${childMapServiceId}`,
        updatePayload
      );
      this.$emit("layer-added");
    },
  },
};
</script>
