var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-row",
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "400" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "m-2", attrs: { id: "signin-card" } },
                [
                  _c("validation-observer", { ref: "form" }, [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "v-toolbar",
                          {
                            staticClass: "elevation-0",
                            attrs: { dark: "", color: "primary" },
                          },
                          [
                            _c("v-toolbar-title", [_vm._v("Sign In")]),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.reload()
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-2",
                                    attrs: { size: "18" },
                                  },
                                  [_vm._v(_vm._s(_vm.mdiReload))]
                                ),
                                _vm._v(" Reload"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          {
                            style: {
                              height: _vm.$vuetify.breakpoint.xsOnly
                                ? `${_vm.contentHeight}px`
                                : "auto",
                              "overflow-y": "auto",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              { staticClass: "pb-0" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex justify-center",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/UtiliSync_Admin_Logo.svg"),
                                        id: "logo",
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0 my-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Username",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function ({ errors, valid }) {
                                            return [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "prepend-icon":
                                                    _vm.mdiAccount,
                                                  label: "Username *",
                                                  "hide-details": "auto",
                                                  "error-messages": errors,
                                                  success: valid,
                                                  color: "primary",
                                                  name: "username",
                                                  disabled: _vm.loggingIn,
                                                  id: "username",
                                                },
                                                model: {
                                                  value: _vm.user.username,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "username",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "user.username",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pt-2",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Password",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function ({ errors, valid }) {
                                            return [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "prepend-icon": _vm.mdiLock,
                                                  label: "Password *",
                                                  type: "password",
                                                  "hide-details": "auto",
                                                  "error-messages": errors,
                                                  success: valid,
                                                  color: "primary",
                                                  name: "password",
                                                  disabled: _vm.loggingIn,
                                                  id: "password",
                                                },
                                                model: {
                                                  value: _vm.user.password,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "password",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "user.password",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { staticClass: "py-0 my-1" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          type: "submit",
                                          elevation: "0",
                                          raised: "",
                                          width: "100%",
                                          color: "primary",
                                          depressed: "",
                                          disabled: _vm.loggingIn,
                                          dark: "",
                                          id: "sign-in",
                                        },
                                      },
                                      [_vm._v(" Sign In ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { staticClass: "pb-0 my-0" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex justify-end py-0 my-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "pa-0 ma-0",
                                        attrs: {
                                          text: "",
                                          color: "primary",
                                          id: "forgot-password",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.showForgotPasswordDialog = true
                                          },
                                        },
                                      },
                                      [_vm._v(" Forgot Password? ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "strike" }, [
                              _c("span", [_vm._v("OR")]),
                            ]),
                            _c(
                              "v-row",
                              { staticClass: "py-0 my-0" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          type: "button",
                                          elevation: "0",
                                          raised: "",
                                          width: "100%",
                                          color: "primary",
                                          outlined: "",
                                          id: "arcgis-online",
                                        },
                                        on: { click: _vm.openArcGisSignIn },
                                      },
                                      [_c("div", [_vm._v("ArcGIS Online")])]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          type: "button",
                                          elevation: "0",
                                          raised: "",
                                          width: "100%",
                                          color: "primary",
                                          outlined: "",
                                          id: "arcgis-enterprise",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.showPortalUrlDialog = true
                                          },
                                        },
                                      },
                                      [_c("div", [_vm._v("ArcGIS Enterprise")])]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "500" },
              model: {
                value: _vm.showLoginFailedDialog,
                callback: function ($$v) {
                  _vm.showLoginFailedDialog = $$v
                },
                expression: "showLoginFailedDialog",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "m-2" },
                [
                  _c("v-card-text", [
                    _c("div", { staticClass: "pt-5" }, [
                      _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
                    ]),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            elevation: "0",
                            raised: "",
                            text: "",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showLoginFailedDialog = false
                            },
                          },
                        },
                        [_vm._v(" Close ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showPortalUrlDialog
            ? _c("ArcGisEnterpriseLoginDialog", {
                attrs: { showPortalUrlDialog: _vm.showPortalUrlDialog },
                on: {
                  "portal-url-dialog-close": function ($event) {
                    _vm.showPortalUrlDialog = false
                  },
                  "portal-login-success": _vm.onPortalLoginSuccess,
                  "show-invalid-arcgis-user-dialog": function ($event) {
                    _vm.showArcGisLoginFailureDialog = true
                    _vm.error = $event
                  },
                },
              })
            : _vm._e(),
          _c("ForgotPasswordDialog", {
            attrs: { showForgotPasswordDialog: _vm.showForgotPasswordDialog },
            on: {
              "forgot-password-dialog-close": function ($event) {
                _vm.showForgotPasswordDialog = false
              },
              "password-reset-email-sent": _vm.onPasswordResetEmailSent,
            },
          }),
          _c("CreateNewPasswordDialog", {
            attrs: {
              showCreateNewPasswordDialog: _vm.showCreateNewPasswordDialog,
            },
            on: {
              "reset-password-dialog-close": function ($event) {
                _vm.showCreateNewPasswordDialog = false
              },
              "password-reset": _vm.onPasswordReset,
            },
          }),
          _vm.showArcGisLoginFailureDialog
            ? _c("ArcGisLoginFailureDialog", {
                attrs: {
                  "show-arc-gis-login-failure-dialog":
                    _vm.showArcGisLoginFailureDialog,
                  error: _vm.error,
                },
                on: {
                  close: function ($event) {
                    _vm.showArcGisLoginFailureDialog = false
                  },
                },
              })
            : _vm._e(),
          _c("InvalidPasswordResetTokenDialog", {
            attrs: {
              "show-invalid-password-reset-token-dialog":
                _vm.showInvalidPasswordResetTokenDialog,
            },
            on: {
              close: function ($event) {
                _vm.showInvalidPasswordResetTokenDialog = false
              },
            },
          }),
          _c(
            "v-snackbar",
            {
              staticClass: "link-snackbar",
              model: {
                value: _vm.showPasswordResetEmailSnackbar,
                callback: function ($$v) {
                  _vm.showPasswordResetEmailSnackbar = $$v
                },
                expression: "showPasswordResetEmailSnackbar",
              },
            },
            [
              _c(
                "section",
                { staticClass: "d-flex align-center justify-space-between" },
                [
                  _c("div", { staticStyle: { "white-space": "nowrap" } }, [
                    _vm._v(
                      " A link to reset your password has been sent to the email address associated with your account. "
                    ),
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-0 ma-0",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showPasswordResetEmailSnackbar = false
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-snackbar",
            {
              model: {
                value: _vm.showPasswordResetSnackbar,
                callback: function ($$v) {
                  _vm.showPasswordResetSnackbar = $$v
                },
                expression: "showPasswordResetSnackbar",
              },
            },
            [
              _c(
                "section",
                { staticClass: "d-flex justify-space-between align-center" },
                [
                  _c("div", [_vm._v("Your password has now been reset.")]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-0 ma-0",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showPasswordResetSnackbar = false
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }