<template>
  <div class="mt-6 mb-3">
    <p class="caption">Layer Details</p>

    <v-card>
      <v-card-text>
        <p v-if="['U', 'F'].includes(layer.service_type)">
          <v-btn
            text
            color="primary"
            @click="showUtiliSyncFieldsDialog = true"
            id="utilisyncFields"
          >
            UtiliSync Fields
          </v-btn>
        </p>
        <p v-if="['F'].includes(layer.service_type)">
          <v-btn
            text
            color="primary"
            @click="showGisFieldsDialog = true"
            id="gisFields"
          >
            GIS Fields
          </v-btn>
        </p>
        <p v-if="!layer.parent_map_service_id">
          <v-btn
            text
            color="primary"
            @click="showCustomLayerSymbologyDialog = true"
            id="mapSymbols"
          >
            Map Symbols
          </v-btn>
        </p>
        <p>
          <v-btn
            text
            color="primary"
            @click="showFormsDialog = true"
            id="forms"
          >
            Forms
          </v-btn>
        </p>
      </v-card-text>
    </v-card>

    <EditUtiliSyncFieldsDialog
      v-if="showUtiliSyncFieldsDialog"
      :mapServiceId="layer.map_service_id"
      :showUtiliSyncFieldsDialog="showUtiliSyncFieldsDialog"
      @edit-utilisync-fields-dialog-close="showUtiliSyncFieldsDialog = false"
      @utilisync-fields-saved="onUtilisyncFieldsSaved"
    />

    <EditGisFieldsDialog
      v-if="showGisFieldsDialog"
      :mapServiceId="layer.map_service_id"
      :showGisFieldsDialog="showGisFieldsDialog"
      @edit-gis-fields-dialog-close="showGisFieldsDialog = false"
      @gis-fields-saved="onGisFieldsSaved"
    />

    <EditCustomLayerSymbologyDialog
      @custom-symbology-dialog-close="showCustomLayerSymbologyDialog = false"
      @symbology-saved="showCustomLayerSymbologyDialog = false"
      :showCustomLayerSymbologyDialog="showCustomLayerSymbologyDialog"
      :mapServiceId="layer.map_service_id"
      :layerObj="layer"
      v-if="showCustomLayerSymbologyDialog"
    />

    <FormsDialog
      v-if="showFormsDialog"
      :showFormsDialog="showFormsDialog"
      :mapServiceId="layer.map_service_id"
      @forms-dialog-close="showFormsDialog = false"
      @open-edit-form-dialog="$emit('open-edit-form-dialog', $event)"
      @create-new-form="$emit('create-new-form')"
    />
  </div>
</template>

<script>
import EditUtiliSyncFieldsDialog from "@/components/layers/edit-layer-form/shared/layer-details/EditUtiliSyncFieldsDialog";
import EditCustomLayerSymbologyDialog from "@/components/layers/edit-layer-form/shared/layer-details/EditCustomLayerSymbologyDialog";
import FormsDialog from "@/components/layers/edit-layer-form/shared/layer-details/FormsDialog";
import EditGisFieldsDialog from "@/components/layers/edit-layer-form/shared/layer-details/EditGisFieldsDialog";

export default {
  name: "LayerDetails",
  props: {
    layer: Object,
  },
  components: {
    EditUtiliSyncFieldsDialog,
    EditCustomLayerSymbologyDialog,
    FormsDialog,
    EditGisFieldsDialog,
  },
  data() {
    return {
      showUtiliSyncFieldsDialog: false,
      showCustomLayerSymbologyDialog: false,
      showFormsDialog: false,
      showGisFieldsDialog: false,
    };
  },
  methods: {
    onUtilisyncFieldsSaved(layer) {
      this.$emit("utilisync-fields-saved", layer);
    },
    onGisFieldsSaved(layer) {
      this.$emit("gis-fields-saved", layer);
    },
  },
};
</script>
