<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0 page-background mainArea">
      <TopBar title="811 Codes" />
      <PageLayout width="600px">
        <v-data-table
          :headers="headers"
          :items="accounts811"
          item-key="locate_request_provider_account_id"
          disable-pagination
          hide-default-footer
          class="py-0 my-0 cursor-pointer"
          @click:row="rowClick"
          single-select
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex gap">
              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon
                  text
                  @click.stop="
                    showEditNotificationSettingsDialog = true;
                    selected811Account = item;
                  "
                >
                  {{ mdiBell }}
                </v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon
                  text
                  @click.stop="
                    showEditTicketAreasDialog = true;
                    selected811Account = item;
                  "
                >
                  {{ mdiMap }}
                </v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon
                  text
                  @click.stop="
                    showEditWorkflowsDialog = true;
                    selected811Account = item;
                  "
                >
                  {{ mdiArrowDecision }}
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </PageLayout>

      <v-dialog
        v-model="showEdit811AccountDialog"
        max-width="600px"
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <Edit811AccountForm
          v-if="showEdit811AccountDialog"
          @edit-811-account-form-close="showEdit811AccountDialog = false"
          @edit-811-account-form-submitted="
            showEdit811AccountDialog = false;
            get811Accounts();
          "
          :selected811Account="selected811Account"
          :locateRequestProviders="locateRequestProviders"
          disabled
        />
      </v-dialog>

      <v-dialog
        v-model="showEditNotificationSettingsDialog"
        max-width="600px"
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <EditNotificationSettingsForm
          v-if="showEditNotificationSettingsDialog"
          @edit-emergency-notification-settings-form-close="
            showEditNotificationSettingsDialog = false
          "
          @edit-emergency-notification-settings-form-submitted="
            showEditNotificationSettingsDialog = false;
            loadData();
          "
          :users="users"
          :selected811Account="selected811Account"
          :locateRequestProviders="locateRequestProviders"
        />
      </v-dialog>

      <v-dialog
        v-model="showEditTicketAreasDialog"
        max-width="600px"
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <EditTicketAreasForm
          @edit-ticket-areas-form-close="showEditTicketAreasDialog = false"
          @edit-ticket-areas-form-submitted="showEditTicketAreasDialog = false"
          v-if="showEditTicketAreasDialog"
          :users="users"
          :selected811Account="selected811Account"
        />
      </v-dialog>

      <v-dialog
        v-model="showEditWorkflowsDialog"
        max-width="700px"
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <EditWorkflowsForm
          @edit-811-workflows-form-close="showEditWorkflowsDialog = false"
          @edit-ticket-areas-form-submitted="showEditWorkflowsDialog = false"
          @workflows-saved="
            showEditWorkflowsDialog = false;
            get811Accounts();
          "
          v-if="showEditWorkflowsDialog"
          :selected811Account="selected811Account"
          :locateRequestProviders="locateRequestProviders"
        />
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import Edit811AccountForm from "@/components/accounts-811/Edit811AccountForm.vue";
import EditNotificationSettingsForm from "@/components/accounts-811/EditNotificationSettingsForm.vue";
import EditTicketAreasForm from "@/components/accounts-811/EditTicketAreasForm.vue";
import EditWorkflowsForm from "@/components/accounts-811/EditWorkflowsForm.vue";
import {
  mdiMagnify,
  mdiCog,
  mdiDelete,
  mdiPencil,
  mdiBell,
  mdiMap,
  mdiArrowDecision,
} from "@mdi/js";
import axios from "axios";
import usetifulMixin from "@/mixins/usetifulMixin";
import PageLayout from "@/components/layouts/PageLayout.vue";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;
const headers = [
  {
    text: "Label",
    align: "start",
    sortable: false,
    value: "label",
  },
  {
    text: "811 Code",
    align: "start",
    sortable: false,
    value: "name",
  },
  {
    text: "Actions",
    align: "end",
    sortable: false,
    value: "actions",
  },
];

export default {
  name: "Accounts811",
  components: {
    TopBar,
    Edit811AccountForm,
    EditNotificationSettingsForm,
    EditTicketAreasForm,
    EditWorkflowsForm,
    PageLayout,
  },
  mixins: [usetifulMixin, permissionsMixin],
  data() {
    return {
      search: "",
      mdiMagnify,
      mdiCog,
      mdiDelete,
      mdiPencil,
      mdiBell,
      mdiMap,
      mdiArrowDecision,
      accounts811: [],
      showEdit811AccountDialog: false,
      selected811Account: {},
      headers,
      showConfirmDelete811AccountDialog: false,
      accountToDelete: undefined,
      showEditNotificationSettingsDialog: false,
      showEditTicketAreasDialog: false,
      showEditWorkflowsDialog: false,
      users: [],
      notificationSettingsAllAccounts: [],
      locateRequestProviders: [],
    };
  },
  methods: {
    rowClick(item, row) {
      row.select(true);
      this.showEdit811AccountDialog = true;
      this.selected811Account = item;
    },
    async getUserNotificationSettingsAllAccounts() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/notifications/provider_accounts/admin`);
      this.notificationSettingsAllAccounts = results;
    },
    async getUsers() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/users`);
      this.users = results.filter(
        (user) =>
          user.is_active === true && user.receive_811_notifications === true
      );
    },
    async get811Accounts() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/locate_request_provider_accounts/all`);
      this.accounts811 = results.sort((a, b) => a.label.localeCompare(b.label));
    },
    async loadData() {
      await this.get811Accounts();
      await this.getUsers();
      await this.getUserNotificationSettingsAllAccounts();
    },
    async getLocateRequestProviders() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/locate_request_providers`);
      this.locateRequestProviders = results;
    },
  },
  beforeMount() {
    this.loadData();
    this.getLocateRequestProviders();
  },
};
</script>

<style scoped>
tr.v-data-table__selected::v-deep {
  background: #f5f5f5 !important;
}
</style>
