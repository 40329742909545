var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showFormsDialog,
        "max-width": "600px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", color: "primary" },
            },
            [
              _c("v-toolbar-title", [_vm._v("Forms")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("forms-dialog-close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              staticClass: "py-5",
              style: {
                height: _vm.$vuetify.breakpoint.xsOnly
                  ? `${_vm.contentHeight}px`
                  : "auto",
                "overflow-y": "auto",
                "max-height": _vm.$vuetify.breakpoint.xsOnly
                  ? undefined
                  : "60vh",
              },
            },
            [
              !_vm.showEditDialog
                ? _c("v-data-table", {
                    staticClass: "elevation-0",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.forms,
                      "item-key": "form_definition_id",
                      "disable-pagination": "",
                      "hide-default-footer": "",
                      "custom-filter": _vm.filter,
                      search: _vm.search,
                    },
                    on: { "click:row": _vm.openEditFormDialog },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "top",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("v-text-field", {
                                    staticClass: "mx-4",
                                    attrs: {
                                      label: "Search",
                                      name: "search",
                                      "append-icon": "mdi-magnify",
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function ($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "mt-4 mr-4" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            id: "add-form-button",
                                            disabled: !_vm.canManageLayers,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showNewFormDialog = true
                                              _vm.selectedFormDefinitionId =
                                                undefined
                                            },
                                          },
                                        },
                                        [_vm._v(" + Form ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2 edit-button",
                                  attrs: {
                                    text: "",
                                    disabled: !_vm.canManageLayers,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.openEditFormDialog(item, true)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.mdiPencil) + " ")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "delete-button",
                                  attrs: {
                                    text: "",
                                    disabled: !_vm.canManageLayers,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      _vm.showDeleteDialog = true
                                      _vm.itemToDelete = item
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.mdiDelete) + " ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
              _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "400px",
                    fullscreen: _vm.$vuetify.breakpoint.xsOnly,
                  },
                  model: {
                    value: _vm.showNewFormDialog,
                    callback: function ($$v) {
                      _vm.showNewFormDialog = $$v
                    },
                    expression: "showNewFormDialog",
                  },
                },
                [
                  _vm.showNewFormDialog
                    ? _c("MapServiceNewFormForm", {
                        attrs: { mapServiceId: _vm.mapServiceId },
                        on: {
                          "new-form-close": function ($event) {
                            _vm.showNewFormDialog = false
                          },
                          "create-new-form": _vm.onCreateNewForm,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    fullscreen: "",
                    "retain-focus": false,
                    persistent: "",
                    "content-class": "form-builder-edit-form",
                  },
                  model: {
                    value: _vm.showEditDialog,
                    callback: function ($$v) {
                      _vm.showEditDialog = $$v
                    },
                    expression: "showEditDialog",
                  },
                },
                [
                  _vm.showEditDialog
                    ? _c("FormBuilderEditForm", {
                        attrs: {
                          selectedFormDefinitionId:
                            _vm.selectedFormDefinitionId,
                          formIsNewForm: _vm.formIsNewForm,
                        },
                        on: {
                          "form-builder-edit-form-submitted": function (
                            $event
                          ) {
                            _vm.selectedFormDefinitionId = $event
                          },
                          "form-builder-edit-form-close":
                            _vm.onFormBuilderEditFormClose,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.showDeleteDialog
                ? _c("ConfirmDeleteFormDialog", {
                    attrs: {
                      itemToDelete: _vm.itemToDelete,
                      showDeleteDialog: _vm.showDeleteDialog,
                    },
                    on: {
                      "cancel-confirm-delete-form": function ($event) {
                        _vm.showDeleteDialog = false
                      },
                      "form-deleted": function ($event) {
                        _vm.showDeleteDialog = false
                        _vm.getForms()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }