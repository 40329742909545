<template>
  <section>
    <v-card>
      <v-card-text class="py-1 my-1">
        <section class="d-flex justify-space-between align-center">
          <div>This UtiliBot will add labor to Cityworks service request.</div>

          <v-switch
            v-model="
              formDefinition.form.city_works_webhook_config
                .add_labor_to_cityworks_service_request.enabled
            "
            label="Enable Integration"
            @change="$emit('input', formDefinition)"
            :disabled="!canManageForms"
          >
          </v-switch>
        </section>

        <p>
          Select a form field to use to create a labor record on the service
          request.
        </p>

        <section style="width: 70%" class="py-2">
          <validation-provider
            v-slot="{ errors, valid }"
            name="Employee"
            :rules="{ required: integrationEnabled }"
          >
            <v-select
              autocomplete="off"
              label="Employee *"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="employee"
              :items="singleSelectQuestionChoices"
              item-text="label"
              item-value="value"
              v-model="
                formDefinition.form.city_works_webhook_config
                  .add_labor_to_cityworks_service_request.employeeFieldId
              "
              @change="$emit('input', formDefinition)"
              :disabled="!canManageForms"
            >
              <template #append-outer>
                <v-btn
                  fab
                  small
                  icon
                  @click="showFormFieldDialog(LABOR_FORM_FIELD.EMPLOYEE)"
                >
                  <v-icon color="primary"> {{ mdiInformation }} </v-icon>
                </v-btn>
              </template>
            </v-select>
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Hours"
            :rules="{ required: integrationEnabled }"
          >
            <v-select
              autocomplete="off"
              label="Hours *"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="hours"
              :items="numberQuestionChoices"
              item-text="label"
              item-value="value"
              v-model="
                formDefinition.form.city_works_webhook_config
                  .add_labor_to_cityworks_service_request.hoursFieldId
              "
              @change="$emit('input', formDefinition)"
              :disabled="!canManageForms"
            >
              <template #append-outer>
                <v-btn fab small icon>
                  <v-icon
                    color="primary"
                    @click="showFormFieldDialog(LABOR_FORM_FIELD.HOURS)"
                  >
                    {{ mdiInformation }}
                  </v-icon>
                </v-btn>
              </template>
            </v-select>
          </validation-provider>

          <validation-provider
            v-slot="{ errors, valid }"
            name="Time Type *"
            :rules="{ required: integrationEnabled }"
          >
            <v-select
              autocomplete="off"
              label="Time Type *"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="formField"
              :items="multiSelectQuestionChoices"
              item-text="label"
              item-value="value"
              v-model="
                formDefinition.form.city_works_webhook_config
                  .add_labor_to_cityworks_service_request.timeTypeFieldId
              "
              @change="$emit('input', formDefinition)"
              :disabled="!canManageForms"
            >
              <template #append-outer>
                <v-btn fab small icon>
                  <v-icon
                    color="primary"
                    @click="showFormFieldDialog(LABOR_FORM_FIELD.TIME_TYPE)"
                  >
                    {{ mdiInformation }}
                  </v-icon>
                </v-btn>
              </template>
            </v-select>
          </validation-provider>

          <validation-provider v-slot="{ errors, valid }" name="Description">
            <v-select
              autocomplete="off"
              label="Description"
              hide-details="auto"
              :error-messages="errors"
              :success="valid"
              color="primary"
              name="description"
              :items="textQuestionChoices"
              item-text="label"
              item-value="value"
              v-model="
                formDefinition.form.city_works_webhook_config
                  .add_labor_to_cityworks_service_request.descriptionFieldId
              "
              @change="$emit('input', formDefinition)"
              clearable
              :disabled="!canManageForms"
            >
              <template #append-outer>
                <v-btn fab small icon>
                  <v-icon
                    color="primary"
                    @click="showFormFieldDialog(LABOR_FORM_FIELD.DESCRIPTION)"
                  >
                    {{ mdiInformation }}
                  </v-icon>
                </v-btn>
              </template>
            </v-select>
          </validation-provider>
        </section>
      </v-card-text>
    </v-card>

    <CityworksLaborFormFieldInfoDialog
      :formFieldInfoDialogFieldName="formFieldInfoDialogFieldName"
      :showCityworksLaborFormFieldInfoDialog="
        showCityworksLaborFormFieldInfoDialog
      "
      @close="showCityworksLaborFormFieldInfoDialog = false"
    />
  </section>
</template>

<script>
import { cloneDeep } from "lodash";
import permissionsMixin from "@/mixins/permissionsMixin";
import questionsMixin from "@/mixins/questionsMixin";
import { FIELD_TYPES } from "@/constants/question";
import { mdiInformation } from "@mdi/js";
import CityworksLaborFormFieldInfoDialog from "@/components/forms/form-builder-edit-form/cityworks-form/cityworks-labor-form/CityworksLaborFormFieldInfoDialog";
import { LABOR_FORM_FIELD } from "@/constants/cityworks";

export default {
  name: "CityworksLaborForm",
  props: {
    value: Object,
  },
  mixins: [questionsMixin, permissionsMixin],
  components: { CityworksLaborFormFieldInfoDialog },
  computed: {
    integrationEnabled() {
      return Boolean(
        this.formDefinition?.form.city_works_webhook_config
          ?.add_labor_to_cityworks_service_request.enabled
      );
    },
    textQuestionChoices() {
      const { questions } = this;
      return questions
        .filter((item) => item.question.type === FIELD_TYPES.TEXT)
        .map(({ id, number, question }) => ({
          value: id,
          label: `${number} ${question?.label}`,
        }));
    },
    singleSelectQuestionChoices() {
      const { questions } = this;
      return questions
        .filter((item) => item.question.type === FIELD_TYPES.SINGLE_SELECT)
        .map(({ id, number, question }) => ({
          value: id,
          label: `${number} ${question?.label}`,
        }));
    },
    numberQuestionChoices() {
      const { questions } = this;
      return questions
        .filter((item) => item.question.type === FIELD_TYPES.NUMBER)
        .map(({ id, number, question }) => ({
          value: id,
          label: `${number} ${question?.label}`,
        }));
    },
    multiSelectQuestionChoices() {
      const { questions } = this;
      return questions
        .filter((item) => item.question.type === FIELD_TYPES.MULTI_SELECT)
        .map(({ id, number, question }) => ({
          value: id,
          label: `${number} ${question?.label}`,
        }));
    },
  },
  data() {
    return {
      formDefinition: {},
      mdiInformation,
      LABOR_FORM_FIELD,
      formFieldInfoDialogFieldName: undefined,
      showCityworksLaborFormFieldInfoDialog: false,
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.formDefinition = cloneDeep(val);
        if (
          !this.formDefinition.form.city_works_webhook_config
            .add_labor_to_cityworks_service_request
        ) {
          this.formDefinition.form.city_works_webhook_config.add_labor_to_cityworks_service_request =
            {
              enabled: false,
            };
        }
      },
    },
  },
  methods: {
    showFormFieldDialog(formFieldInfoDialogFieldName) {
      this.formFieldInfoDialogFieldName = formFieldInfoDialogFieldName;
      this.showCityworksLaborFormFieldInfoDialog = true;
    },
  },
};
</script>
